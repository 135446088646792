

































































import permissionDialog from "./permissionDialog.vue";
import {Component, Vue} from "vue-property-decorator";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {getPageAPIs, Util} from "@/common/util";
import PermissionSetAPI from "@/common/api/permissionSet";
import {mergeStrategyAddNewParams} from "@/common/autoCollectMenus";


export const pageApiList = getPageAPIs(PermissionSetAPI);
@Component({
  components:{
    permissionDialog
  }
})
export default class UserList extends Vue {
  private permissionSet: any = new PermissionSetAPI();
  private deepLoopUtil: any;

  private form: any = {
    time: [], // 时间选择
  };
  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
  }
  private visible: boolean = false;
  private addForms: any = {
    department_name: "",
  };
  private operateType:string = 'add'
  private rules:any =  {
    department_name: [
      { required: true, message: '请输入名称', trigger: 'blur' },
    ],
  }


  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.permissionSet.getList(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }


  private onSetPermission(departItem:any){
    this.$refs.permissionDialog.show(departItem)
  }

  private addRole(type: any='add',row:any) {
    this.operateType = type
    this.visible = true;
    this.restForm();
    if(this.operateType==='edit'){
      this.addForms = row;
    }
  }

  private deleteRole(row: any): void {
    console.log(row)
    this.$confirm(`确认删除${row.department_name}吗？`, "删除提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.permissionSet.deleteDepartment(
          {id: row.id,},
          () => {
            Util.showToast("删除成功");
            this.getTable();
          }
      );
    })

  }

  private submit(): void {
    this.$refs['addFormsRef'].validate((valid)=>{
      if(valid){
        if (this.operateType === "add") {
          this.permissionSet.addDepartment(this.addForms, () => {
            this.visible = false;
            Util.showToast("新增成功");
            this.getTable();
          });
        } else {
          this.permissionSet.editDepartment(this.addForms, () => {
            this.visible = false;
            Util.showToast("修改成功");
            this.search();
          });
        }
      }
    })



  }

  private restForm(): void {
    this.addForms = new Util().clearObject(this.addForms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }

  private sumbitBacthAddNewRightMenuLoading: boolean = false
  // 获取收集新增分类、页面以及权限 自动批量新增
  private handleAutoBatchAddMenus() {
    mergeStrategyAddNewParams().then((addlistParams)=>{
      console.log('新增上传菜单',addlistParams)
      const resultParams = JSON.stringify(addlistParams)

      this.sumbitBacthAddNewRightMenuLoading = true

      this.permissionSet.batchAddMenuList({
        data: resultParams
      }, () => {
        this.sumbitBacthAddNewRightMenuLoading = false
        this.$message.success('批量新增成功')
      }, () => {
        this.sumbitBacthAddNewRightMenuLoading = false
      })
    })
  }
}
