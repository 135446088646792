
import PermissionSetAPI from "@/common/api/permissionSet";
import {Component, Vue} from "vue-property-decorator";
import {Util} from "@/common/util";
import { getConfigMapList } from '@/common/asideMenu'
import { DeepLoop } from "@/common/deepLoop/deepLoop";
@Component({})
export default class MergePools extends Vue {
  private visible: boolean = false
  private apiClass: any = new PermissionSetAPI();
  private deepLoopUtil: any;

  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
  }
  private show(departItem: string): void {
    this.curChooseDepartmentItem = departItem
    this.initAllMenusConfigList()
    this.visible = true
  }

  /** 部门权限配置 */
  private curChooseDepartmentItem: any = {
    // department_name: '测试部门'
  }

  private rightMenuSettingHeaders: any[] = [
    {
      label: '',
      name: 'select',
    },
    {
      label: '菜单名称',
      name: 'menu-name',
    },
    {
      label: '菜单功能',
      name: 'menu-children',
    }
  ]
  private rightMenuFlexTables = [
    1,
    5,
    7
  ]
  private editDepartmentMenuSettingLoading: boolean = false
  private curRightMenusList: any[] = []
  private curRightMenusMapList: Map<string, any> = new Map()
  private curChooseRightMenuIdsList: any[] = []
  private curRightMenusOriginList: any[] = []
  private allMenusCheck: boolean = false
  private curIdMapParentsIds: Map<string, any[]> = new Map()
  private handleChangeExpend(menuCategoryItem: any) {
    const curRightMenusList = this.curRightMenusList
    const existCategoryIndex = curRightMenusList.findIndex((item: any) => item.id == menuCategoryItem.id)
    if (existCategoryIndex > -1) {
      curRightMenusList[existCategoryIndex].__is_expend = !curRightMenusList[existCategoryIndex].__is_expend
    }
  }
  private bfcMenuList(list: any[], handle: Function, mode: string = 'every') {
    const queue: any[] = [
      ...(list || [])
    ]

    let result: any = 1

    while (queue.length) {
      const item = queue.shift()

      switch (mode) {
        case 'every':
          result &= handle(item)
          break;
        case 'some':
          result = handle(item)
          if (result) {
            return result
          }
          break;
      }

      if (Array.isArray(item.child)) {
        queue.push(
            ...(item.child)
        )
      }
    }

    switch (mode) {
      case 'every':

        return result
      case 'some':

        return false
    }
  }
  private bfcMenuMapList(list: Map<string, any[]>, handle: Function) {
    const queue: any[] = []

    for (const [_, item] of list) {
      queue.push(item)
    }

    // console.log(queue, 'queue')

    while (queue.length) {
      const mapItem = queue.shift()

      handle(mapItem)

      if (mapItem.children && mapItem.children.size > 0) {
        for (const [_, child] of mapItem.children) {
          queue.push(child)
        }
      }
    }
  }
  // 改变所有menu 选中状态
  private handleChangeAllChooseMenus(check_status: boolean) {
    if (check_status) {
      const curRightMenusList = this.curRightMenusList

      const chooseMenuIds: any[] = []
      this.bfcMenuList(curRightMenusList, (item: any) => {
        item.is_selected = 1

        chooseMenuIds.push(item.id)
      })

      console.log(chooseMenuIds, 'chooseMenuIds')

      this.curChooseRightMenuIdsList = chooseMenuIds
    } else {
      const curRightMenusList = this.curRightMenusList

      this.bfcMenuList(curRightMenusList, (item: any) => {
        item.is_selected = 0
      })
      this.curChooseRightMenuIdsList = []
    }
  }

  // 改变某一个menu 选中状态
  private handleChangeChooseMenu(check_status: boolean, menuItem: any) {
    const curIdMapParentsIds = this.curIdMapParentsIds

    const curRightMenusMapList = this.curRightMenusMapList

    const idsList: any[] = []

    const allParentsIds = [
      ...(curIdMapParentsIds.get(menuItem.id) || []),
    ]

    function getMenuLevelMapList(menuItem: any, curRightMenusMapList: Map<string, any>) {
      const parent_ids = curIdMapParentsIds.get(menuItem.id) || []

      function getCurMapListByParentMapItem(parent_ids: any[], mapList: Map<string, any>, level: number = 0): Map<string, any> {
        if (parent_ids.length == 0) {
          return mapList
        }
        const parentMapItem = mapList.get(parent_ids[level])

        if (level < parent_ids.length - 1) {
          return getCurMapListByParentMapItem(parent_ids, parentMapItem.children, level + 1)
        }

        return parentMapItem.children
      }

      return getCurMapListByParentMapItem(parent_ids, curRightMenusMapList)
    }

    function getMenuChildrenIds(menuItem: any, mapList: Map<string, any>) {
      const menuMapItem = mapList.get(menuItem.id) || {}

      const idsList: any[] = []

      if (menuMapItem.children && menuMapItem.children.size > 0) {
        for (const [childId, child] of menuMapItem.children) {
          idsList.push(childId)
          idsList.push(
              ...getMenuChildrenIds(child, menuMapItem.children)
          )
        }
      }


      return idsList
    }

    function getChildIdsByCurId(menuId: any) {
      const mapList = getMenuLevelMapList({id: menuId}, curRightMenusMapList)

      return getMenuChildrenIds({id: menuId}, mapList)
    }


    idsList.push(
        ...(allParentsIds || []),
        menuItem.id,
    )

    switch (menuItem.level) {
      case 1:
        idsList.push(
            ...getMenuChildrenIds(menuItem, curRightMenusMapList)
        )
        break;
      case 2:
        idsList.push(
            ...getMenuChildrenIds(menuItem, getMenuLevelMapList(menuItem, curRightMenusMapList))
        )
        break;
      case 3:
      default:
    }

    if (check_status) {
      const curChooseRightMenuIdsList = Array.from(new Set([
        ...(this.curChooseRightMenuIdsList),
        ...idsList
      ]))

      this.curChooseRightMenuIdsList = curChooseRightMenuIdsList
    } else {
      const curChooseRightMenuIdsList = this.curChooseRightMenuIdsList

      const hadSpliceIds: any[] = []

      for (let len = curChooseRightMenuIdsList.length, i = len - 1; i >= 0; --i) {
        const id = curChooseRightMenuIdsList[i]

        const childrenIds = getChildIdsByCurId(id)

        if (idsList.includes(id) && !childrenIds.some((id) => curChooseRightMenuIdsList.includes(id)) && !hadSpliceIds.includes(id)) {

          curChooseRightMenuIdsList.splice(i, 1)
          hadSpliceIds.push(id)
        }
      }

    }
  }

  private async initAllMenusConfigList() {
    const curChooseDepartmentItem = this.curChooseDepartmentItem

    if (!curChooseDepartmentItem.id) {
      this.$message.error('未获取到部门信息，请重试')
      return
    }
    this.apiClass.manageMenu({
      department_id: curChooseDepartmentItem.id
    }, (res: any) => {
      const {list} = res.data || {}

      const mapList = getConfigMapList(list, 'exist-id')

      // 是否选中 0否 1是
      const allMenusCheck = !this.bfcMenuList(list, (item: any) => item.is_selected == 0, 'some')

      this.curRightMenusOriginList = this.deepLoopUtil.deepCopy(list)

      this.allMenusCheck = allMenusCheck

      const curChooseRightMenuIdsList: any[] = []

      this.bfcMenuList(list, (item: any) => {
        item.__is_expend = false //默认全部不展开

        if (item.is_selected == 1) {
          curChooseRightMenuIdsList.push(item.id)
        }
      })

      this.bfcMenuMapList(mapList, (mapItem: any) => {
        this.curIdMapParentsIds.set(mapItem.id, mapItem.__parent_ids)
      })

      this.curChooseRightMenuIdsList = curChooseRightMenuIdsList
      this.curRightMenusList = list
      this.curRightMenusMapList = mapList
    })
  }

  // 获取已经去重的menuids
  private getChooseUnqiueMenuIds() {
    const curChooseRightMenuIdsList = this.curChooseRightMenuIdsList

    const resultList: any[] = Array.from(new Set([
      ...curChooseRightMenuIdsList
    ])).filter((id: any) => !!id)

    return resultList
  }

  private getDepartmentMenuSettingParams() {
    const curChooseDepartmentItem = this.curChooseDepartmentItem

    const params = {
      department_id: curChooseDepartmentItem.id,
      menu_ids: this.getChooseUnqiueMenuIds().join(',')
    }

    if (!params.menu_ids) {
      this.$message.error("请至少选中一项权限")
      return false
    }

    return params
  }
  // 提交部门权限配置
  handleSubmitEditDepartmentMenuSetting() {
    const params = this.getDepartmentMenuSettingParams()

    if (typeof params != 'object') return

    this.editDepartmentMenuSettingLoading = true
    this.apiClass.editDepartRightmenus(params, () => {
      this.editDepartmentMenuSettingLoading = false
      this.visible = false
      Util.showToast("保存成功");
      this.$emit('success')
    }, () => {
      this.editDepartmentMenuSettingLoading = false
    })
  }
}

